import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { AbbyArcadeCode } from '~/services/abby/enums/AbbyArcadeCode.enum'

export const useArcadeStore = () => {
  const useArcadeStore = defineStore('arcadeStore', () => {
    const isOpened = ref(false)
    const arcadeCode = ref<AbbyArcadeCode | null>(null)

    const toggleArcadeModal = (value: boolean, _arcadeCode?: AbbyArcadeCode) => {
      isOpened.value = value
      arcadeCode.value = _arcadeCode || null
    }

    return {
      isOpened,
      arcadeCode,
      toggleArcadeModal,
    }
  })

  const arcadeStore = useArcadeStore()
  const { toggleArcadeModal } = arcadeStore
  const {
    isOpened,
    arcadeCode,
  } = storeToRefs(arcadeStore)

  return {
    isOpened,
    arcadeCode,
    toggleArcadeModal,
  }
}
