import { extend } from 'vee-validate'
import dayjs from 'dayjs'
import validator from 'validator'
// eslint-disable-next-line camelcase
import { required, min, min_value, max, is_not, is, digits, regex, excluded, between } from 'vee-validate/dist/rules'

extend('required', required)
extend('min', min)
extend('min_value', min_value)
extend('max', max)
extend('is_not', is_not)
extend('is', is)
extend('digits', digits)
extend('regex', regex)
extend('excluded', excluded)
extend('between', between)

extend('email', {
  validate (value) {
    if (value) {
      return validator.isEmail(value, { allow_utf8_local_part: false })
    }
    return false
  },
})

extend('emailchars', {
  validate (value) {
    if (value) {
      const regex = /^[a-z0-9@._-]+$/
      return regex.test(value)
    }
    return false
  },
})

extend('fqdn', {
  validate (value) {
    if (value) {
      return /^(?=.{1,254}$)((?=[a-z0-9-]{1,63}\.)(xn--+)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/i.test(value)
    }
    return false
  },
})

extend('requiredPhone', {
  params: ['dialCode'],
  validate (value, { dialCode }) {
    return (value !== `+${dialCode}`) && !!value
  },
})

extend('urssafTpPhone', {
  message: 'Le numéro de téléphone n\'est pas valide',
  params: ['dialCode'],
  validate (value) {
    return /^(0|\+33|\+262|\+594|\+590|\+689)\s?[6-7](\s?[0-9]{2}){4}$/.test(value)
  },
})

extend('shouldBeDifferent', {
  params: ['target'],
  validate (value, { target }) {
    return value !== target
  },
  message: 'Les champs sont identiques',
})

extend('url', {
  validate (value) {
    if (value) {
      return /^(?:^|[ \t])((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)$/i.test(value)
    }
    return false
  },
})

extend('dateOnSameMonth', {
  message: 'Les dates doivent être du même mois et de la même année',
  validate: (_value, [start, end]) => {
    if (!start || !end) { return true }
    return dayjs(start).isSame(end, 'month') && dayjs(start).isSame(end, 'year')
  },
})

extend('dateBefore', {
  message: 'La date de début doit être inférieure à la date de fin',
  validate: (_value, [start, end]) => {
    if (!start || !end) { return true }
    return dayjs(start).isBefore(end)
  },
})

extend('dateSameOrBeforeCreation', {
  message: 'La date de début doit être inférieure ou égale à la date de fin et ne doit pas être antérieure à la création du client',
  validate: (_value, [start, end, registeredAt]) => {
    if (!start || !end || !registeredAt) { return true }
    return (dayjs(start).isBefore(end) || dayjs(end).isSame(start)) &&
        (dayjs(registeredAt).isBefore(start) || dayjs(registeredAt).isSame(start, 'day'))
  },
})

extend('dateAfter', {
  message: (_, value) => {
    const date = dayjs(value[0]).format('DD/MM/YYYY')
    return `La date doit être supérieur à ${date}`
  },
  validate: (_value, [date]) => {
    // return true
    return !dayjs(date).isAfter(dayjs(_value, 'DD/MM/YYYY'))
  },
})

extend('dateSameOrBefore', {
  message: (_, value) => {
    const date = dayjs(value[0]).format('DD/MM/YYYY')
    return `La date de début doit être inférieure ou égale à ${date}`
  },
  validate: (_value, [date]) => {
    return dayjs(date).isSameOrBefore(dayjs(_value, 'DD/MM/YYYY'))
  },
})

extend('dateSameOrAfter', {
  message: 'La date de fin doit être égale ou supérieure à la date de début et doit être inférieure ou égale à la date du jour',
  validate: (_value, [start, end]) => {
    if (!start || !end) { return true }
    return (dayjs(end).isAfter(start) || dayjs(end).isSame(start)) && ((dayjs(start).isBefore(dayjs(end)) || dayjs(start).isSame(dayjs(end)))) && dayjs(end).isSameOrBefore()
  },
})

extend('bic', {
  message: 'Le BIC n\'est pas valide',
  validate (value) {
    if (value) {
      return /^([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)$/i.test(value)
    }
    return false
  },
})

extend('iban', {
  message: 'L\'IBAN n\'est pas valide',
  validate (value) {
    if (value) {
      const ibanStripped = value.replace(/[^A-Z0-9]+/gi, '') // keep numbers and letters only
        .toUpperCase() // calculation expects upper-case
      const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/)
      if (!m) { return false }

      const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, function (ch) {
        // replace upper-case characters by numbers 10 to 35
        return (ch.charCodeAt(0) - 55)
      })
      // The resulting number would be to long for javascript to handle without loosing precision.
      // So the trick is to chop the string up in smaller parts.
      const mod97 = numbericed.match(/\d{1,7}/g)
        .reduce(function (total, curr) { return Number(total + curr) % 97 }, '')

      return (mod97 === 1)
    }
    return false
  },
})

extend('nir', {
  validate (value) {
    if (value === 0 || value === '0') { return true }
    if (value) {
      const nirInfo = value.match(/(?<sexe>[1-478])(?<annee>[0-9]{2})(?<mois>0[1-9]|1[0-2]|62|63)(?<dept>2[ABab]|[0-9]{2})(?<commune>00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)(?<ordre>00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(?<cle>0[1-9]|[1-8][0-9]|9[0-7])/)

      if (!nirInfo) {
        return false
      }
      const [, sexe, annee, mois, dept, commune, ordre, cle] = nirInfo

      const numero = sexe + annee + mois + dept.replace('2A', '19').replace('2B', '18') + commune + ordre
      const calculCle = 97 - (+numero % 97)

      return +cle === calculCle
    }
    return false
  },
})

extend('vat', {
  validate (value) {
    if (value === 0 || value === '0') { return true }
    if (value) {
      return /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/.test(value)
    }
    return false
  },
})

extend('siret', {
  validate (value) {
    if (value === 0 || value === '0') { return true }
    if (value) {
      const nirInfo = value.match(/(?<sexe>[1-478])(?<annee>[0-9]{2})(?<mois>0[1-9]|1[0-2]|62|63)(?<dept>2[ABab]|[0-9]{2})(?<commune>00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)(?<ordre>00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(?<cle>0[1-9]|[1-8][0-9]|9[0-7])/)

      if (!nirInfo) {
        return false
      }
      const [, sexe, annee, mois, dept, commune, ordre, cle] = nirInfo

      const numero = sexe + annee + mois + dept.replace('2A', '19').replace('2B', '18') + commune + ordre
      const calculCle = 97 - (+numero % 97)

      return +cle === calculCle
    }
    return false
  },
})

extend('siren', {
  validate (value) {
    if (!value) { return true }
    if (value) {
      let estValide
      if ((value.length !== 9) || (isNaN(value))) { estValide = false } else {
        // Donc le SIREN est un numérique à 9 chiffres
        let somme = 0
        let tmp
        for (let cpt = 0; cpt < value.length; cpt++) {
          if ((cpt % 2) === 1) { // Les positions paires : 2ème, 4ème, 6ème et 8ème chiffre
            tmp = value.charAt(cpt) * 2 // On le multiplie par 2
            if (tmp > 9) { tmp -= 9 } // Si le résultat est supérieur à 9, on lui soustrait 9
          } else { tmp = value.charAt(cpt) }
          somme += parseInt(tmp)
        }
        estValide = (somme % 10) === 0
      }
      return estValide
    }
    return false
  },
})

extend('siret', {
  validate (value) {
    if (!value) { return true }
    if (value) {
      let estValide
      if ((value.length !== 14) || (isNaN(value))) { estValide = false } else {
        // Donc le SIRET est un numérique à 14 chiffres
        // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
        // correspondent au numéro d'établissement
        // et enfin le dernier chiffre est une clef de LUHN.
        let somme = 0
        let tmp
        for (let cpt = 0; cpt < value.length; cpt++) {
          if ((cpt % 2) === 0) { // Les positions impaires : 1er, 3è, 5è, etc...
            tmp = value.charAt(cpt) * 2 // On le multiplie par 2
            if (tmp > 9) { tmp -= 9 } // Si le résultat est supérieur à 9, on lui soustrait 9
          } else { tmp = value.charAt(cpt) }
          somme += parseInt(tmp)
        }
        estValide = (somme % 10) === 0
      }
      return estValide
    }
    return false
  },
})

extend('tpClientInput', {
  message: 'La clé n\'est pas valide',
  validate (value) {
    if (value) {
      return /^([a-z0-9]{8})-(([a-z0-9]{4})-){3}([a-z0-9]{12})/g.test(value)
    }
    return false
  },
})
