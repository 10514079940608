import { useContext } from '@nuxtjs/composition-api'
import { AbbyPlans, PlanFeature, StripeProductType } from '@abby/core-legacy'
import { AccessOptions } from '~/plugins/planManager'
import { PaymentCallbacks, PaymentParams } from '~/store/payment'

const mapStripeProductToAbbyPlans = {
  [StripeProductType.ABBY_FREE]: AbbyPlans.ABBY_FREE,
  [StripeProductType.ABBY_START]: AbbyPlans.ABBY_START,
  [StripeProductType.ABBY_PRO]: AbbyPlans.ABBY_PRO,
}

export const usePlanManager = () => {
  const { $planManager } = useContext()
  return {
    hasAccessToFeature: (feature: PlanFeature, options?: AccessOptions) => {
      return $planManager.hasAccessTo(feature, options)
    },
    openPlansModal: (feature: PlanFeature, options?: PaymentParams['options'], callbacks?: PaymentCallbacks) => {
      $planManager.openAbbyPlansModal({ feature, ...(options && { params: { options } }), ...(callbacks && { callbacks }) })
    },
    openAbbyPlansModal: $planManager.openAbbyPlansModal,
    whichPlanForFeature: (feature: PlanFeature) => {
      const legacyStripeProduct = $planManager.whichPlanFor(feature)
      return mapStripeProductToAbbyPlans[legacyStripeProduct as (StripeProductType.ABBY_FREE | StripeProductType.ABBY_START | StripeProductType.ABBY_PRO)]
    },
    whichPlanCompanyHas: () => $planManager.whichPlanCompanyHas(),
    whichPlanCompanyHasSubscribed: () => {
      return $planManager.whichPlanCompanyHasSubscribed()
    },
    whichFrequencyPlanCompanyHas: () => {
      return $planManager.whichFrequencyPlanCompanyHas()
    },
    has: (plans: StripeProductType | StripeProductType[] | null | undefined) => {
      return $planManager.has(plans)
    },
    displayAbbyCreationPlans: () => {
      return $planManager.displayAbbyCreationPlans()
    },
    isTrial: $planManager.isTrial,
    isTrialExpired: $planManager.isTrialExpired,
    abGroupPricing: $planManager.abGroupPricing,
    features: $planManager.features,
    plans: $planManager.plans,
    failedInformation: $planManager.failedInformation,
    timeRemainingBeforeTrialEnd: $planManager.timeRemainingBeforeTrialEnd,
  }
}
