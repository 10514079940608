import { Plugin } from '@nuxt/types'
import { AxiosError } from 'axios'
import { useToasterManagerStore } from '~/composables/abby/manager/useToasterManager.store'

export type ToasterType = 'info' | 'success' | 'warning' | 'error'

export interface ToasterParams {
  title?: string
  message: string
  icon?: string,
  timeout?: number,
  dismissible?: boolean
  direction?: {
    x: 'left' | 'right'
    y: 'top' | 'bottom'
  }
  button?: {
    text: string
    direction?: 'left' | 'right'
    action: () => void
  },
}

export interface Toaster {
  title?: string
  message: string
  createdAt: number
  icon?: string
  type: ToasterType
  timeout?: number,
  exiting: boolean,
  timerId?: number,
  paused: boolean,
  dismissible: boolean
  direction: {
    x: 'left' | 'right'
    y: 'top' | 'bottom'
  }
  button?: {
    text: string
    direction?: 'left' | 'right'
    action: () => void
  },
}

export type AbbyError = AxiosError & { config: any, response?: any, status: number, message?: string }

export interface ToasterManagerInstance {
  error(params: ToasterParams): void;
  success(params: ToasterParams): void;
  warning(params: ToasterParams): void;
  info(params: ToasterParams): void;
  autoError(error: AbbyError, toasterType?: 'warning' | 'error'): void;
}

const ToasterManagerPlugin: Plugin = (context, inject) => {
  const toasterManagerStore = useToasterManagerStore()

  const defaultConfig: { dismissible: boolean, exiting: boolean, paused: boolean, direction: { x: 'left' | 'right', y: 'top' | 'bottom' }} = {
    dismissible: true,
    exiting: false,
    paused: false,
    direction: {
      x: 'right',
      y: 'top',
    },
  }

  const calculateTimeNeededToReadMessage = (message: string) => {
    const readingTimeNeeded = message.split(' ').length * 240 + 500
    return readingTimeNeeded > 5000 ? readingTimeNeeded : 5000
  }

  const logError = (error: AbbyError) => {
    if (!['production', 'staging'].includes(context.$config.nodeEnv as string)) {
    // eslint-disable-next-line no-console
      console.trace(error)
    }
  }

  const buildErroMessage = (error: AbbyError) => {
    let message: string = context.app.i18n.t('error.basic_error') as string
    const code = error.response?.data?.code || error?.code

    if (error.response?.data.message) {
      message = context.app.i18n.t(`error.${error.response.data.message as string}`) as string
    } else if (code) {
      const errorMessage = context.app.i18n.t(`error.${code as string}`)
      if (errorMessage !== `error.${code as string}`) {
        message = errorMessage as string
      }
    }
    return message
  }

  const alertError = (params: ToasterParams) => {
    toasterManagerStore.addToaster({
      ...defaultConfig,
      ...params,
      type: 'error',
      direction: params?.direction || defaultConfig.direction,
      dismissible: params?.dismissible || defaultConfig.dismissible,
      timeout: params?.timeout || calculateTimeNeededToReadMessage(params.message),
      createdAt: Date.now(),
    })
  }

  const alertWarning = (params: ToasterParams) => {
    toasterManagerStore.addToaster({
      ...defaultConfig,
      ...params,
      type: 'warning',
      direction: params?.direction || defaultConfig.direction,
      dismissible: params?.dismissible || defaultConfig.dismissible,
      timeout: params?.timeout || calculateTimeNeededToReadMessage(params.message),
      createdAt: Date.now(),
    })
  }

  const alertSuccess = (params: ToasterParams) => {
    toasterManagerStore.addToaster({
      ...defaultConfig,
      ...params,
      type: 'success',
      direction: params?.direction || defaultConfig.direction,
      dismissible: params?.dismissible || defaultConfig.dismissible,
      timeout: params?.timeout || calculateTimeNeededToReadMessage(params.message),
      createdAt: Date.now(),
    })
  }

  const alertInfo = (params: ToasterParams) => {
    toasterManagerStore.addToaster({
      ...defaultConfig,
      ...params,
      type: 'info',
      direction: params?.direction || defaultConfig.direction,
      dismissible: params?.dismissible || defaultConfig.dismissible,
      timeout: params?.timeout || calculateTimeNeededToReadMessage(params.message),
      createdAt: Date.now(),
    })
  }

  const autoError = (error: AbbyError, toasterType: 'warning' | 'error' = 'error') => {
    context?.$hotjar?.sendHotjarEvent('error')
    logError(error)
    const message = buildErroMessage(error)
    const alertFunction = toasterType === 'warning' ? alertWarning : alertError
    alertFunction({
      message,
    })
  }

  inject('toasterManager', {
    error: alertError,
    warning: alertWarning,
    success: alertSuccess,
    info: alertInfo,
    autoError,
  })

}

declare module 'vue/types/vue' {
  interface Vue {
    $toasterManager: ToasterManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $toasterManager: ToasterManagerInstance
  }
  interface Context {
    $toasterManager: ToasterManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $toasterManager: ToasterManagerInstance
  }
}

export default ToasterManagerPlugin
