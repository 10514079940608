import { computed } from 'vue'
import { PlanFeature } from '@abby/core-legacy/'
import { usePlanManager } from '~/composables/abby/manager/action/usePlan.manager'
import { useBankRepository } from '~/composables/accounting/bank/useBank.repository'

export const useHasAccessToBankSynchronization = () => {
  const planManager = usePlanManager()
  const { data: accounts } = useBankRepository().fetchBankAccounts()
  return computed(() => {
    const totalBankAccountsSynchronized = accounts.value?.filter(account => account.isSynced).length || 0
    return {
      hasAccess: planManager.hasAccessToFeature(PlanFeature.ACCOUNTING_BANKING_SYNCHRONIZATION, { totalBankAccountsSynchronized }),
      totalBankAccountsSynchronized,
    }
  })
}
