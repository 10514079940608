import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { useContext } from '@nuxtjs/composition-api'
import { QueryBankAccounts } from '@abby/shared/'
import { BasePaginate } from '@abby/core-legacy/'
import { computed, Ref } from 'vue'
import axios from 'axios'
import { BankItemMapper } from '~/services/accounting/bank/mappers/BankItem.mapper'
import { BankAccountMapper } from '~/services/accounting/bank/mappers/BankAccount.mapper'
import { BankItem } from '~/services/accounting/bank/entities/BankItem.entity'
import { BankAccountSynchronizeForm } from '~/services/accounting/bank/forms/BankAccountSynchronize.form'
import { BankAccount } from '~/services/accounting/bank/entities/BankAccount.entity'

export const useBankRepository = () => {
  const { $backend, $api } = useContext()
  const queryClient = useQueryClient()
  return {
    queryClient,
    fetchBankItems: () => useQuery({
      queryClient,
      queryKey: ['bankItems'],
      queryFn: async () => {
        const results = await $backend.bank.getBankItems()
        return results.map(result => BankItemMapper.fromHttp(result))
      },
    }),
    fetchBankAccountsByItemId: (itemId: string) => useQuery({
      queryClient,
      queryKey: ['bankAccounts', itemId],
      queryFn: async () => {
        const results = await $backend.bank.getAccountsByItemId(itemId)
        return results.map(BankAccountMapper.fromHttp)
      },
      enabled: !!itemId,
    }),
    fetchBankAccounts: (options?: QueryBankAccounts) => useQuery({
      queryClient,
      queryKey: ['bankAccounts'],
      queryFn: async () => {
        const results = await $backend.bank.getAccounts(options)
        return results.map(BankAccountMapper.fromHttp)
      },
    }),
    fetchBankTransaction: async (transactionId: string) => {
      return await $backend.transaction.fetchTransaction(transactionId)
    },
    fetchBankTransactions: (options: Ref<{ accountId?: string, toAnnotate?: boolean, search?: string, filters?: any[], sorts?: any[], enabled?: boolean, withoutAnyInvoicePayments?: boolean, negative?: boolean }>) => useInfiniteQuery({
      queryClient,
      refetchOnWindowFocus: false,
      enabled: computed(() => options.value?.enabled),
      queryKey: ['transactions', options],
      queryFn: async ({ pageParam = 1, signal }) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        signal?.addEventListener('abort', () => source.cancel())
        return await $api.transaction.paginate({
          ...(options.value.accountId ? { accountId: options.value.accountId } : {}),
          ...(options.value.toAnnotate ? { toAnnotate: true } : {}),
          ...(options.value.search ? { keywords: options.value.search } : {}),
          ...(options.value.filters?.length ? options.value.filters.reduce((acc, filter) => ({ ...acc, [filter.type]: filter.value }), {}) : {}),
          ...(options.value.sorts?.length ? { sortBy: options.value.sorts.map(sort => sort.column), sortDesc: options.value.sorts.map(sort => sort.value === 1) } : {}),
          ...(options.value.withoutAnyInvoicePayments ? { withoutAnyInvoicePayments: true } : {}),
          ...(options.value.negative != null ? { negative: options.value.negative } : {}),
          page: pageParam,
          limit: 25,
          cancelToken: source.token,
        })
      },
      getNextPageParam: (lastPage: BasePaginate<unknown>) => {
        if (lastPage.currentPage < lastPage.maxPages) {
          return lastPage.currentPage + 1
        }
        return undefined
      },
    }),
    annotateTransaction: useMutation({
      queryClient,
      mutationFn: ({ transaction, payload }: any) => $api.transaction.annotate(transaction.id, payload),
      onSuccess: (value: any) => queryClient.setQueriesData({
        queryKey: ['transactions'],
        exact: false,
      }, (oldData: any) => {
        if (!oldData) { return }
        const newPages = oldData.pages.map((page: any) => ({
          ...page,
          data: page.data.map((transaction: any) => {
            if (transaction.id === value.id) {
              return value
            }
            return transaction
          }),
        }))
        return {
          ...oldData,
          pages: newPages,
        }
      }),
    }),
    removeTransaction: useMutation({
      queryClient,
      mutationFn: (transactionId: string) => $api.transaction.delete(transactionId),
      onMutate: (transactionId: string) => queryClient.setQueriesData({
        queryKey: ['transactions'],
        exact: false,
      }, (oldData: any) => {
        if (!oldData) { return }
        const newPages = oldData.pages.map((page: any) => ({
          ...page,
          data: page.data.filter((transaction: any) => transaction.id !== transactionId),
        }))
        return {
          ...oldData,
          pages: newPages,
        }
      }),
    }),
    refreshBankItem: useMutation({
      mutationFn: (bankItem: BankItem) => $backend.bank.refreshBankItem(bankItem.id),
      onMutate: (bankItem: BankItem) => queryClient.setQueryData(['bankItems'], (items: BankItem[] = []) => {
        const index = items.findIndex(item => item.id === bankItem.id)
        items[index].startRefreshAt = new Date()
        return items
      }),
      onError: () => queryClient.invalidateQueries(['bankItems']),
      onSuccess: () => queryClient.invalidateQueries(['bankItems']),
    }),
    editBankItem: useMutation({
      mutationFn: (bankItem: BankItem) => $backend.bank.editBankItem(bankItem.id),
      onMutate: (bankItem: BankItem) => queryClient.setQueryData(['bankItems'], (items: BankItem[] = []) => {
        const index = items.findIndex(item => item.id === bankItem.id)
        items[index].startRefreshAt = new Date()
        return items
      }),
      onError: () => queryClient.invalidateQueries(['bankItems']),
    }),
    synchronizeBankAccount: useMutation({
      mutationFn: (form: BankAccountSynchronizeForm) => $backend.bank.synchronizeBankAccount(form.bankAccountId, {
        startDateTransactionImport: form.synchronizeAt,
      }),
      onMutate: (form: BankAccountSynchronizeForm) => queryClient.setQueryData(['bankAccounts', form.bankItemId], (accounts: BankAccount[] = []) => {
        const index = accounts.findIndex(account => account.id === form.bankAccountId)
        accounts[index].isSyncing = true
        return accounts
      }),
      onError: () => {
        queryClient.invalidateQueries(['bankAccounts'])
        queryClient.invalidateQueries(['bankItems'])
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['bankItems'])
        queryClient.invalidateQueries(['bankAccounts'])
      },
    }),
    disconnectBankAccount: useMutation({
      mutationFn: (bankAccountId: string) => $backend.bank.unSynchronizeBankAccount(bankAccountId),
      onMutate: (bankAccountId: string) => {
        queryClient.setQueriesData({
          queryKey: ['bankAccounts'],
          exact: false,
        }, (accounts: BankAccount[] = []) => {
          const index = accounts.findIndex(account => account.id === bankAccountId)
          if (index !== -1) {
            accounts[index].isSyncing = true
          }
          return accounts
        })
      },
      onError: () => queryClient.invalidateQueries(['bankAccounts']),
      onSuccess: () => queryClient.invalidateQueries(['bankAccounts']),
    }),
    archiveBankItem: useMutation({
      mutationFn: (itemId: string) => $backend.bank.archiveBankItem(itemId),
      onMutate: (itemId: string) => queryClient.setQueryData(['bankItems'], (items: BankItem[] = []) => items.filter(item => item.id !== itemId)),
      onError: () => queryClient.invalidateQueries(['bankItems']),
      onSuccess: () => queryClient.invalidateQueries(['bankItems']),
    }),
    removeBankItem: useMutation({
      mutationFn: (itemId: string) => $backend.bank.deleteBankItem(itemId),
      onMutate: (itemId: string) => queryClient.setQueryData(['bankItems'], (items: BankItem[] = []) => items.filter(item => item.id !== itemId)),
      onError: () => queryClient.invalidateQueries(['bankItems']),
      onSuccess: () => queryClient.invalidateQueries(['bankItems']),
    }),
  }
}
