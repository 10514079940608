import { ActionTree, GetterTree } from 'vuex'
import debounce from 'lodash/debounce'
import type {
  BasePaginateMongo,
  CreateCustomer,
  ICustomer,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface CustomersQuery {
  search?: string,
  professional?: boolean
  forCertificate: boolean
}

export interface CustomersPaginationQuery { page: number, limit: number, sortBy: string[] | null, sortDesc: boolean[] | null }

export interface CustomersState {
  pagination: BasePaginateMongo<ICustomer> | null;
  query: CustomersQuery;
  queryLoading: boolean;
  initialLoading: boolean;
  forCertificate?: boolean;
  paginationQuery: CustomersPaginationQuery

}

export const state = (): CustomersState => ({
  pagination: null,
  paginationQuery: {
    page: 1,
    limit: 25,
    sortBy: null,
    sortDesc: null,
  },
  query: {
    search: undefined,
    professional: undefined,
    forCertificate: false,
  },
  queryLoading: true,
  initialLoading: true,
})

export const getters: GetterTree<CustomersState, RootState> = {
  pagination (state: CustomersState) {
    return state.pagination
  },
  query (state: CustomersState) {
    return state.query
  },
  paginationQuery (state: CustomersState) {
    return state.paginationQuery
  },
  customers (state: CustomersState) {
    return state.pagination?.docs || []
  },
  queryLoading (state: CustomersState) {
    return state.queryLoading
  },
  initialLoading (state: CustomersState) {
    return state.initialLoading
  },
}

export const mutations = {
  SET_PAGINATION_QUERY (state: CustomersState, paginationQuery: CustomersPaginationQuery) {
    state.paginationQuery = paginationQuery
  },
  SET_PAGINATION (state: CustomersState, pagination: BasePaginateMongo<ICustomer> | null): void {
    state.pagination = pagination
  },
  SET_INITIAL_LOADING (state: CustomersState, value: boolean): void {
    state.initialLoading = value
  },
  SET_QUERY_LOADING (state: CustomersState, value: boolean): void {
    state.queryLoading = value
  },
  UPDATE_QUERY (_state: CustomersState, query: Partial<CustomersQuery>): void {
    _state.query = {
      ..._state.query,
      ...query,
    }
    const newPaginationQuery = state().paginationQuery
    _state.paginationQuery = Object.assign(_state.paginationQuery, newPaginationQuery)
  },
  RESET (_currentState: CustomersState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  RESET_QUERY (_currentState: CustomersState) {
    const newQuery = state().query
    const newPaginationQuery = state().paginationQuery
    _currentState.query = Object.assign(_currentState.query, newQuery)
    _currentState.paginationQuery = Object.assign(_currentState.paginationQuery, newPaginationQuery)
  },
}

export const actions: ActionTree<any, any> = {
  async fetchCustomers ({ commit, getters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const pagination = await this.$api.billing.getAllCustomers({
        ...getters.paginationQuery as CustomersPaginationQuery,
        ...getters.query,
        countWithoutFilters: true,
      })
      commit('SET_PAGINATION', pagination)
    } finally {
      commit('SET_INITIAL_LOADING', false)
      commit('SET_QUERY_LOADING', false)
    }
  },
  debounceFetchCustomers: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchCustomers')
  }, 300, { leading: true }),
  updateQuery ({ commit, dispatch }, query: Partial<CustomersQuery>) {
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchCustomers')
  },
  updateQueryWithoutFetch ({ commit }, query: Partial<CustomersQuery>) {
    commit('UPDATE_QUERY', query)
  },
  updatePaginationQuery ({ commit, dispatch }, query: Partial<CustomersPaginationQuery>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchCustomers')
  },
}
