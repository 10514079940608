
import { Time, Duration } from '@abby/time'
import { Action, Component, Getter, Vue, Watch } from 'nuxt-property-decorator'
import {
  AbbyPlans,
  ABGroupPricing,
  CampaignType,
  ICompany,
  StripeProductFrequency,
  StripeProductType,
} from '@abby/core-legacy'
import { Action as AbbyAction } from '@abby/shared'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'
import Page from '~/components-legacy/layout/containers/Page.vue'
import { CampaignsState } from '~/store/campaigns'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import ASChoiceInput from '~/components-legacy/inputs/ASChoiceInput.vue'
import AbbyLink from '~/components/abby/buttons/AbbyLink.vue'
import Modal from '~/components-legacy/layout/containers/Modal.vue'
import ASNewModal from '~/components-legacy/modals/ASNewModal.vue'
import NewPaymentCard from '~/components-legacy/cards/payment/NewPaymentCard.vue'
import VimeoPlayer from '~/components-legacy/academy/VimeoPlayer.vue'
import { useConfirmManager } from '~/composables/abby/manager/action/useConfirm.manager'

@Component({
  components: {
    ASNewModal,
    AbbyLink,
    ASChoiceInput,
    AbbyPlanChip,
    Page,
    ASDialog,
    Modal,
    NewPaymentCard,
    VimeoPlayer,
  },
  setup () {
    const confirmManager = useConfirmManager()
    return { confirmManager }
  },
})
export default class MultipleYearsSubscriptionModal extends Vue {
  @Getter('campaigns/modals') campaignsModal!: CampaignsState['modals'];
  @Action('payment/fetchSubscriptions') fetchSubscriptions!: () => Promise<void>;
  @Getter('company/company') company!: ICompany

  loading: boolean = true
  frequency: StripeProductFrequency = StripeProductFrequency.YEAR
  paymentModalOpened: boolean = false
  promotionTimer = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    daysStr1: '0',
    daysStr2: '0',
    hoursStr1: '0',
    hoursStr2: '0',
    minutesStr1: '0',
    minutesStr2: '0',
    secondsStr1: '0',
    secondsStr2: '0',
  }

  price: ABGroupPricing = ABGroupPricing.RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9
  proratedAmount: number = 0
  renewAt: Time = Time.now()

  confirmManager!: ReturnType<typeof useConfirmManager>

  get renew1YearsAccessTime () {
    return {
      start: Time.now().format({ format: 'DD/MM/YYYY' }),
      end: Time.now().add(Duration.days(365)).format({ format: 'DD/MM/YYYY' }),
    }
  }

  get renew2YearsAccessTime () {
    return {
      start: Time.now().format({ format: 'DD/MM/YYYY' }),
      end: Time.now().add(Duration.days(2 * 365)).format({ format: 'DD/MM/YYYY' }),
    }
  }

  get renew3YearsAccessTime () {
    return {
      start: Time.now().format({ format: 'DD/MM/YYYY' }),
      end: Time.now().add(Duration.days(3 * 365)).format({ format: 'DD/MM/YYYY' }),
    }
  }

  get ABGroupPricing () {
    return ABGroupPricing
  }

  get AbbyPlans () {
    return AbbyPlans
  }

  chooseOffer (price: ABGroupPricing.RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9 | ABGroupPricing.RENEW_OFFER_3_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9) {
    this.price = price
    this.openPaymentModal()
  }

  get product () {
    return StripeProductType.ABBY_PRO
  }

  get paymentModalTitle () {
    if (this.price === ABGroupPricing.RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9) {
      return 'S\'abonner maintenant pour 2 ans'
    }
    if (this.price === ABGroupPricing.RENEW_OFFER_3_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9) {
      return 'S\'abonner maintenant pour 3 ans'
    }
    if (this.price === ABGroupPricing.RENEW_OFFER_1_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS) {
      return 'S\'abonner maintenant pour 1 an'
    }
    if (this.price === ABGroupPricing.RENEW_OFFER_2_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS) {
      return 'S\'abonner maintenant pour 2 ans'
    }
    return 'S\'abonner maintenant'
  }

  get reviews () {
    return [
      {
        avatar: '/plans/multipleYearsSubscription/reviews/avatar-1.png',
        name: 'Arnaud Decault',
        activity: 'Entrepreneur indépendant',
        plan: AbbyPlans.ABBY_BUSINESS,
        content: '“J’ai gagné un temps précieux. Merci !”',
      },
    ]
  }

  get endDate () {
    return `${this.promotionTimer.days}j ${this.promotionTimer.hours}h ${this.promotionTimer.minutes}m ${this.promotionTimer.seconds}s`
  }

  async openPaymentModal () {
    await this.$store.dispatch('upsells/setPromoCode', null)
    this.paymentModalOpened = true
  }

  closePaymentModal () {
    this.paymentModalOpened = false
  }

  paymentSucceed () {
    this.$campaignsManager.doNotDisplayAnymore(CampaignType.RENEW_OFFER_CAMPAIGN_END)
    this.closePaymentModal()
    this.close()
  }

  mounted () {
    setInterval(() => {
      const endDate = this.$dayjs('2024-12-31').endOf('d').toDate().getTime()
      const now = this.$dayjs().toDate().getTime()
      const diff = endDate - now

      let seconds = Math.floor(diff / 1000)
      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      seconds %= 60
      minutes %= 60
      hours %= 24

      this.promotionTimer = {
        days,
        hours,
        minutes,
        seconds,
        daysStr1: days.toString().padStart(2, '0').charAt(0),
        daysStr2: days.toString().padStart(2, '0').charAt(1),
        hoursStr1: hours.toString().padStart(2, '0').charAt(0),
        hoursStr2: hours.toString().padStart(2, '0').charAt(1),
        minutesStr1: minutes.toString().padStart(2, '0').charAt(0),
        minutesStr2: minutes.toString().padStart(2, '0').charAt(1),
        secondsStr1: seconds.toString().padStart(2, '0').charAt(0),
        secondsStr2: seconds.toString().padStart(2, '0').charAt(1),
      }
    }, 1000)
  }

  get isOpen () {
    return this.campaignsModal.multipleYearsSubscription
  }

  get newPlanToDisplay () {
    const renewDate = Time.fromISO('2025-01-11T00:00:00.000Z')
    if (!this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_price_1Lzgk0I21zkxZU1xi4tu1Yc9)) {
      return {
        old: '168,00 € / an TTC',
        new: '199,90 € / an TTC',
        renewAt: this.renewAt.format({ format: 'DD/MM/YYYY' }),
      }
    } else if (!this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_price_1M7TPuI21zkxZU1xH06XYnPS)) {
      return {
        old: '16,00 € / mois TTC',
        new: '19,00 € / mois TTC',
        renewAt: this.renewAt.isBefore(renewDate) ? this.renewAt.add(Duration.weeks(4)).format({ format: 'DD/MM/YYYY' }) : this.renewAt.format({ format: 'DD/MM/YYYY' }),
      }
    } else { return {} }
  }

  totalAmount1 : number = 33600
  totalAmount2 : number = 39990
  totalAmount3 : number = 19200
  totalAmount4 : number = 33600

  get display2TimesPaymentButton () {
    return !this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_price_1Lzgk0I21zkxZU1xi4tu1Yc9)
  }

  get offers () {
    if (!this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_price_1Lzgk0I21zkxZU1xi4tu1Yc9)) {
      return [{
        type: 'year',
        accessTime: this.renew2YearsAccessTime,
        numberOfYears: 2,
        discountRate: 16,
        baseAmountByYear: 19990,
        baseTotalAmount: 19990,
        amountByYear: 16800,
        totalAmountWithTax: this.totalAmount1,
        abGroup: ABGroupPricing.RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9,
      },
      {
        type: 'year',
        accessTime: this.renew3YearsAccessTime,
        numberOfYears: 3,
        discountRate: 33,
        baseAmountByYear: 19990,
        baseTotalAmount: 19990,
        amountByYear: 13330,
        totalAmountWithTax: this.totalAmount2,
        abGroup: ABGroupPricing.RENEW_OFFER_3_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9,
      }]
    } else if (!this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_price_1M7TPuI21zkxZU1xH06XYnPS)) {
      return [{
        type: 'month',
        accessTime: this.renew1YearsAccessTime,
        numberOfYears: 1,
        discountRate: 16,
        baseAmountByYear: 22800,
        totalAmountWithTax: this.totalAmount3,
        amountByMonth: 1600,
        abGroup: ABGroupPricing.RENEW_OFFER_1_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS,
      },
      {
        type: 'month',
        accessTime: this.renew2YearsAccessTime,
        numberOfYears: 2,
        discountRate: 26,
        baseAmountByYear: 22800,
        totalAmountWithTax: this.totalAmount4,
        amountByMonth: 1400,
        abGroup: ABGroupPricing.RENEW_OFFER_2_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS,
      }]
    }
  }

  set isOpen (value: boolean) {
    this.$store.dispatch('campaigns/closeModal', 'multipleYearsSubscription')
    this.$help.sendChatEvent('multipleYearsSubscriptionClosed')
  }

  async fetchPrices () {
    if (!this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_price_1Lzgk0I21zkxZU1xi4tu1Yc9)) {
      const result1 = await this.$api.stripe.getProductAmount(this.product, {
        abGroup: ABGroupPricing.RENEW_OFFER_2_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9,
        frequency: StripeProductFrequency.YEAR,
      })

      const result2 = await this.$api.stripe.getProductAmount(this.product, {
        abGroup: ABGroupPricing.RENEW_OFFER_3_YEAR_price_1Lzgk0I21zkxZU1xi4tu1Yc9,
        frequency: StripeProductFrequency.YEAR,
      })

      this.totalAmount1 = result1.totalAmount
      this.totalAmount2 = result2.totalAmount
      this.proratedAmount = result1.proratedAmount
      this.renewAt = result1.currentPeriodEnd ? Time.fromUnix(result1.currentPeriodEnd) : Time.now()
    } else if (!this.$campaignsManager.isDisplayable(CampaignType.RENEW_OFFER_price_1M7TPuI21zkxZU1xH06XYnPS)) {
      const result3 = await this.$api.stripe.getProductAmount(this.product, {
        abGroup: ABGroupPricing.RENEW_OFFER_1_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS,
        frequency: StripeProductFrequency.YEAR,
      })
      const result4 = await this.$api.stripe.getProductAmount(this.product, {
        abGroup: ABGroupPricing.RENEW_OFFER_2_YEAR_price_1M7TPuI21zkxZU1xH06XYnPS,
        frequency: StripeProductFrequency.YEAR,
      })

      this.totalAmount3 = result3.totalAmount
      this.totalAmount4 = result4.totalAmount
      this.proratedAmount = result3.proratedAmount
      this.renewAt = result3.currentPeriodEnd ? Time.fromUnix(result3.currentPeriodEnd) : Time.now()
    }

  }

  @Watch('campaignsModal.multipleYearsSubscription')
  async handleIsOpen (value: boolean) {
    if (value) {
      try {
        this.loading = true
        await this.fetchPrices()
        await this.$store.dispatch('upsells/setPromoCode', null)
        await this.$stripe.loadStripe()
        await this.fetchSubscriptions()
        await this.$store.dispatch('company/fetchPlans')
        this.$ap.track(AbbyAction.MULTIPLE_YEARS_SUBSCRIPTION_MODAL_OPENED)
      } catch (e) {
        //
      } finally {
        this.loading = false
      }
    }
  }

  async close (confirm: boolean = false) {
    if (confirm) {
      const confirm = await this.confirmManager.ask({
        title: 'Fermer sans profiter de l\'offre',
        content: 'Vous êtes sur le point de fermer la fenêtre sans profiter des prix avantageux pour votre renouvellement. Voulez-vous vraiment fermer la fenêtre ?',
        confirmText: 'Fermer',
        confirmButton: { depressed: true, color: 'error' },
      })
      if (!confirm) { return }
    }
    this.isOpen = false
  }
}
