import {
  BillingDocumentPDFData,
  IBilling,
  ICompany,
  ICreationCompany,
  CreationCompanyStep,
  customerName,
  customerClientFullName,
  getCompleteTotalAmount,
  IPrice,
  Currency,
  BillingType,
  getLineAmount,
  convertCurrencyAmount,
  formatCents,
  formatVatRate, ProductType,
  productUnitTraduction,
  tpUnitTraduction,
  BillingTemplate,
  defaultColumnsConfig,
  ParcelAddress,
  productTypeRequiredMentionTraduction,
  BillingState,
  PaymentMethod,
  LegalStatus,
  PaymentDelay,
  discountAdvancePaymentTraduction, getDueDate,
  latePenaltyTraduction,
  lumpSumCompensationTraduction,
  paymentDelayTraduction, paymentMethodsTraduction,
  vatMentionTraduction,
} from '@abby/core-legacy'
import dayjs from 'dayjs'
import { currencyPattern, UrssafAddressLetter } from '@abby/shared'
import { IAuthUser } from '~/store/auth'

export const useSetBillingDocumentPDFData = () => {
  return (
    {
      billingDocument,
      createFromBillingDocument,
      company,
      creation,
      user,
      data,
    }: {
      billingDocument: Partial<IBilling> | null,
      createFromBillingDocument?: Partial<IBilling> | null,
      company: ICompany,
      creation: ICreationCompany | null,
      user: IAuthUser,
      data?: Partial<BillingDocumentPDFData>
    }): BillingDocumentPDFData => {

    let creationIsFinished = true
    let companyName = company.name
    const locale = billingDocument?.locale || 'fr'
    if (company.creationId) {
      // Si la société est en cours de création, alors le nom de la company n'existe pas. Pour que la facture soit valide, le nom, le prénom et la mention SIRET en cours d'attribution sont obligatoires
      creationIsFinished = !!creation?.steps[CreationCompanyStep.COMPANY_CREATED]?.finishedAt
      // Le companyName devient le nom et prénom de l'utilisateur
      companyName = `${user.firstname || ''} ${user.lastname || ''}`.trim()
    }

    const hasCustomerCompany = !!billingDocument?.customer?.customerCompany?.name
    const recipientName = billingDocument?.customer ? customerName(billingDocument?.customer) : ''
    const recipientCommercialName = billingDocument?.customer?.customerCompany?.commercialName
    const recipientCompanyContactName = customerClientFullName(billingDocument?.customer)
    const customer = billingDocument?.customer

    const billingAmount: IPrice = getCompleteTotalAmount(billingDocument as IBilling, createFromBillingDocument as IBilling)

    const currency = billingDocument?.currency || Currency.EUR
    const currencyRate = billingDocument?.currencyRate || 1

    // UGLY FIX
    const isAssetFromAdvance = billingDocument?.billingType === BillingType.ASSET && !!createFromBillingDocument
    const vatDetails = billingAmount.vatDetails
    const vatAmount = billingAmount.vatAmount
    const priceTotalTax = billingAmount.priceTotalTax
    const remainingVatDetails = billingAmount.remainingVatDetails
    // UGLY FIX: END -> Ici pas besoin d'ajouter la TVA puisqu'on fait déjà le calcul
    // côté backend pour les avoirs d'acomptes et les acomptes.
    // if (billingDocument?.billingType === BillingType.ASSET && createFromBillingDocument) {
    //   if (createFromBillingDocument.billingType === BillingType.ADVANCE && (createFromBillingDocument.createdFromEstimateId || createFromBillingDocument.createdFromPurchaseOrderId)) {
    //     vatDetails = getCompleteVatDetails(billingDocument as IBilling, createFromBillingDocument.createdFromEstimate || createFromBillingDocument.createdFromPurchaseOrder)
    //     vatAmount = vatDetails.reduce((acc, value) => acc + value.amount, 0)
    //     priceTotalTax += vatAmount
    //   }
    // }

    const products = billingDocument?.product?.map((p) => {
      const lineAmount = getLineAmount(p, currencyRate)
      const linePriceTotalTax = (billingDocument.billingType === BillingType.ADVANCE || isAssetFromAdvance) ? priceTotalTax : lineAmount.priceTotalTax
      const precision = currencyPattern[currency].precision > 0 ? currencyPattern[currency].precision + 1 : 0

      return {
        reference: p.reference || null,
        designation: p.designation || null,
        description: p.description || null,
        quantity: p.quantity,
        productUnit: !billingDocument?.tiersPrestationIsActivatedForThisBilling
          ? p.productUnit ? window.$nuxt.$t(productUnitTraduction(p.productUnit) || '', locale) as string : ''
          : p.tpUnit ? window.$nuxt.$t(tpUnitTraduction(p.tpUnit) || '', locale) as string : '',
        unitPrice: formatCents(convertCurrencyAmount(p.unitPrice || 0, currencyRate), currency, precision).text,
        vatPercentage: formatVatRate(p.vatPercentage || 0),
        priceWithoutTaxBeforeDiscount: formatCents(lineAmount.priceWithoutTaxBeforeDiscount, currency).text,
        priceWithoutTax: formatCents(lineAmount.priceWithoutTax, currency).text,
        priceTotalTax: formatCents(linePriceTotalTax, currency).text,
        discount: lineAmount.discount ? formatCents(-lineAmount.discount, currency).text : undefined,
        productTypeRequiredMention: p.productType !== ProductType.DISBURSEMENT
          ? window.$nuxt.$t(productTypeRequiredMentionTraduction(p.productType
            ? [ProductType.SALE_OF_MANUFACTURED_GOODS, ProductType.SALE_OF_GOODS].includes(p.productType)
            : false,
          p.isDeliveryOfGood) || '', locale) as string || ''
          : '',
      }
    }) || []

    const associatedAdvances = billingAmount.associatedAssetDetails
      ? billingAmount.associatedAssetDetails.map(a => ({
        number: a.number,
        priceTotalTax: formatCents(a.priceTotalTax, currency).text,
      }))
      : null
    const totalAssociatedAsset = billingAmount.totalAssociatedAsset ? formatCents(billingAmount.totalAssociatedAsset, currency).text : null
    const remainingPriceToPayTotalTax = billingAmount.totalAssociatedAsset ? formatCents(billingAmount.remainingPriceToPayTotalTax || 0, currency).text : null
    const remainingPriceToPayWithoutTax = billingAmount.totalAssociatedAsset ? formatCents(billingAmount.remainingPriceToPayWithoutTax || 0, currency).text : null
    const template = data?.template || billingDocument?.template || BillingTemplate.TEMPLATE_1

    const columns = (template === BillingTemplate.TEMPLATE_1) ? defaultColumnsConfig({ hasVat: company?.hasVat || false }) : data?.columns || []

    // @ts-ignore
    // delete data.columns
    // @ts-ignore
    // delete data.template

    let logosFooter: { image: string, type: string }[] = []
    if (billingDocument?.additionalLogos?.length) {
      logosFooter = billingDocument.additionalLogos.map((ad: any) => ({
        image: (ad.relativeUrl ? ad.relativeUrl : ad.url) || '',
        type: 'url',
      }))
    }

    const urssafAddressLetterToLetter = (urssafAddressLetter?: ParcelAddress) => {
      if (!urssafAddressLetter) { return null }
      const _urssafAddressLetterToLetter = {
        B: UrssafAddressLetter.BIS,
        T: UrssafAddressLetter.TER,
        Q: UrssafAddressLetter.QUATER,
        C: UrssafAddressLetter.QUINQUIES,
      }
      return _urssafAddressLetterToLetter[urssafAddressLetter]
    }

    const address = billingDocument?.customer?.location?.address

    const additionalAddress = billingDocument?.customer?.location?.additionalAddress

    return {
      // Utils
      BillingType,
      BillingState,
      PaymentMethod,
      BillingTemplate,
      LegalStatus,
      locale,
      billingType: billingDocument?.billingType || null,
      billingState: billingDocument?.billingState || null,
      number: billingDocument?.number || null,
      logo: billingDocument?.logo?.url || null,
      style: billingDocument?.colors?.primary && !billingDocument?.style
        ? { color: { primary: billingDocument?.colors?.primary }, background: { primary: billingDocument?.colors?.primary } }
        : billingDocument?.style || null,
      shape: billingDocument?.shape?.url || null,
      social: billingDocument?.social || null,
      template,
      creationIsFinished,
      emitter: {
        name: companyName || null,
        fullName: user.fullName || null,
        email: data?.displayTextEmail || user.email || null,
        phone: data?.displayTextPhoneNumber || user.phone || null,
        commercialName: company.commercialName || null,
        siret: company.siret || null,
        vatNumber: company.vatNumber || null,
        rcsNumber: company.rcsNumber || null,
        address: company.address || null,
        additionalAddress: company.additionalAddress || null,
        zipCode: company.zipCode || null,
        city: company.city?.name || null,
        country: 'France',
        hasVat: !!company?.hasVat,
        legalStatus: company.legalStatus || null,
        sapAgreement: company?.siren && billingDocument?.displaySAPAgreement ? `SAP${company?.siren}` : null,
      },
      recipient: {
        hasCompany: hasCustomerCompany,
        name: recipientName && recipientName !== '' ? recipientName : null,
        recipientCompanyContactName: recipientCompanyContactName || null,
        commercialName: recipientCommercialName || null,
        siret: hasCustomerCompany ? customer?.customerCompany?.siret || null : null,
        vatNumber: hasCustomerCompany ? customer?.customerCompany?.vatNumber || null : null,
        address: address || null,
        additionalAddress: additionalAddress || null,
        zipCode: customer?.location?.zipCode || null,
        city: customer?.location?.city || null,
        deliveryLocationIsSame: customer?.deliveryLocationIsSame || false,
        deliveryAddress: customer?.deliveryLocation?.address || null,
        displayDeliveryAddress: !!billingDocument?.displayDeliveryAddress,
        deliveryAdditionalAddress: customer?.deliveryLocation?.additionalAddress || null,
        deliveryZipCode: customer?.deliveryLocation?.zipCode || null,
        deliveryCity: customer?.deliveryLocation?.city || null,
        country: customer?.location?.country
          ? customer?.location?.country.length > 3
            ? customer?.location?.country
            : window.$nuxt.$t(`countries.${customer?.location?.country}`).toString()
          : null,
        deliveryCountry: customer?.deliveryLocation?.country && customer?.deliveryLocation?.country?.length > 2
          ? customer?.deliveryLocation?.country
          : customer?.deliveryLocation?.country
            ? window.$nuxt.$t(`countries.${customer?.deliveryLocation?.country}`).toString()
            : null,
      },
      hasTransferPaymentMethod: !!billingDocument?.paymentMethod?.value?.includes(PaymentMethod.TRANSFER),
      paymentDelay: billingDocument?.paymentDelay?.value ? window.$nuxt.$tc(paymentDelayTraduction(billingDocument?.paymentDelay, { showValue: true }) || '', billingDocument?.paymentDelay?.other ? dayjs(billingDocument?.paymentDelay?.other).startOf('d').diff(dayjs(billingDocument?.date).startOf('d'), 'd') : 0, locale) as string : null,
      latePenalty: billingDocument?.latePenalty?.value ? window.$nuxt.$t(latePenaltyTraduction(billingDocument?.latePenalty) || '', locale) as string : null,
      lumpSumCompensation: billingDocument?.lumpSumCompensation?.value && hasCustomerCompany ? window.$nuxt.$t(lumpSumCompensationTraduction(billingDocument?.lumpSumCompensation) || '', locale) as string : null,
      discountAdvancePayment: billingDocument?.discountAdvancePayment?.value ? window.$nuxt.$t(discountAdvancePaymentTraduction(billingDocument?.discountAdvancePayment) || '', locale) as string : null,
      paymentMethod: billingDocument?.paymentMethod?.value && billingDocument?.paymentMethod.value?.length > 0 ? paymentMethodsTraduction(billingDocument?.paymentMethod, window.$nuxt, locale) : null,
      bank: billingDocument?.bankInformation?.bank || null,
      iban: billingDocument?.bankInformation?.iban || null,
      bic: billingDocument?.bankInformation?.bic || null,
      headerNote: billingDocument?.headerNote || null,
      footerNote: billingDocument?.footerNote || null,
      generalTermsAndConditionsOfSale: billingDocument?.generalTermsAndConditionsOfSale || null,
      mentionMediator: !hasCustomerCompany ? billingDocument?.mentionMediator || null : null,
      date: billingDocument?.date ? window.$nuxt.$dayjs(billingDocument?.date).format('DD/MM/YYYY') : window.$nuxt.$dayjs().format('DD/MM/YYYY'),
      dueDate: window.$nuxt.$dayjs(getDueDate(billingDocument?.paymentDelay?.value ? billingDocument.paymentDelay : { value: PaymentDelay.AT_RECEPTION }, billingDocument?.date ? new Date(billingDocument?.date) : new Date())).format('DD/MM/YYYY'),
      validityDate: billingDocument?.validityDate ? window.$nuxt.$dayjs(billingDocument?.validityDate).format('DD/MM/YYYY') : null,
      deliveryDate: billingDocument?.deliveryDate ? window.$nuxt.$dayjs(billingDocument?.deliveryDate).format('DD/MM/YYYY') : null,
      paidDate: billingDocument?.paidAt ? window.$nuxt.$dayjs(billingDocument?.paidAt).format('DD/MM/YYYY') : null,
      products,
      priceWithoutTaxBeforeDiscount: formatCents(billingAmount.priceWithoutTaxBeforeDiscount, currency).text as string,
      discount: formatCents(billingAmount.discount, currency).text,
      priceWithoutTax: formatCents(billingAmount.priceWithoutTax, currency).text,
      vatAmount: formatCents(vatAmount, currency).text,
      priceTotalTax: formatCents(billingAmount.priceWithoutTax + vatAmount, currency).text,
      vatDetails: vatDetails?.map(v => ({ name: formatVatRate(v.vatPercentage), amount: formatCents(v.amount, currency).text })) || [],
      remainingVatDetails: remainingVatDetails?.map(v => ({ name: formatVatRate(v.vatPercentage), amount: formatCents(v.amount, currency).text })) || [],
      remainingVatAmount: billingAmount?.remainingVatAmount ? formatCents(billingAmount.remainingVatAmount, currency).text : undefined,
      hasAssociatedAdvances: !!(associatedAdvances && associatedAdvances.length > 0),
      hasDiscount: !!billingAmount.discount,
      associatedAdvances,
      totalAssociatedAsset,
      remainingPriceToPayTotalTax,
      remainingPriceToPayWithoutTax,
      vatMention: billingDocument?.vatMention ? window.$nuxt.$t(vatMentionTraduction(billingDocument?.vatMention) || '', locale) as string : null,
      displayMemberOfAnApprovedAssociation: !!billingDocument?.displayMemberOfAnApprovedAssociation,
      displayGoodForApproval: [BillingType.ESTIMATE, BillingType.PURCHASE_ORDER].includes(billingDocument?.billingType || BillingType.INVOICE) && !!billingDocument?.displayGoodForApproval,
      displayAbbyLogo: billingDocument?.displayAbbyLogo !== false,
      displayFullName: billingDocument?.displayFullName !== false,
      displayEmail: billingDocument?.displayEmail !== false,
      displayPhoneNumber: billingDocument?.displayPhoneNumber !== false,
      displayLegalStatus: !!billingDocument?.displayLegalStatus || (billingDocument?.displayLegalStatus !== false && [LegalStatus.EI, LegalStatus.EIRL].includes(company.legalStatus || LegalStatus.EI)),
      displayRequiredMentionsProduct: !!billingDocument?.displayRequiredMentionsProduct,
      logosFooter,
      columns,
      isDefaultCurrency: currency === Currency.EUR,
      currency,
      currencyRate: billingDocument?.currencyRate || 1,
      currencyDate: billingDocument?.currencyDate || new Date(),
      ...data,
    }
  }
}
